import React from "react";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";

interface Props {
  children: React.ReactNode;
}

export const ShowOnMobileOnly = (props: Props) => {
  const windowWidth = useWindowWidth();

  if (windowWidth <= 768) {
    return <>{props.children}</>;
  }

  return null;
};
