import {
  ProductSingleProps,
  ProductSingleVariationProps
} from "../../../../queries/archive";

export const getProductPrices = (
  filteredVariations: ProductSingleVariationProps[],
  product?: ProductSingleProps
) => {
  if (!product) {
    return;
  }

  if (!filteredVariations.length) {
    return {
      regularPrice: product.regularPrice,
      salePrice: product?.salePrice
    };
  }

  return {
    regularPrice: filteredVariations[0].regularPrice,
    salePrice: filteredVariations[0].salePrice
  };
};
