import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { debounce } from "lodash";
import { Col, Container, Row } from "reactstrap";
import { BrandsList } from "../../components/Brands/BrandsList";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { BrandsSidebar } from "../../components/Brands/BrandsSidebar/BrandsSidebar";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { ShowOnMobileOnly } from "../../components/shared/ShowOnMobileOnly/ShowOnMobileOnly";
import { useSearchParams } from "../../lib/hooks/useSearchParams";

import * as brandQueries from "../../queries/brands";
import "./Brands.scss";

const postsLimit = 15;

export const Brands = () => {
  const searchParams = useSearchParams();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { loading, data, error, fetchMore, refetch } = useQuery<
    brandQueries.GetBrandsResponse,
    brandQueries.GetBrandsVariables
  >(brandQueries.getBrands, {
    variables: {
      limit: postsLimit,
      after: "",
      search: "",
      firstLetter: searchParams.firstLetter
        ? (searchParams.firstLetter as string)
        : ""
    }
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);

    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.productBrands?.pageInfo?.endCursor
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.productBrands.nodes;
        const pageInfo = fetchMoreResult.productBrands.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          productBrands: {
            __typename: previousResult.productBrands.__typename,
            nodes: [...previousResult.productBrands.nodes, ...newNodes],
            pageInfo
          }
        };
      }
    });
  };

  const handleSearchChange = debounce((value: string) => {
    refetch({
      limit: postsLimit,
      search: value
    });
  }, 400);

  const handleSelectChange = (value: string) => {
    refetch({
      limit: postsLimit,
      firstLetter: value
    });
  };

  const hasNextPage = !!data?.productBrands?.pageInfo?.hasNextPage;
  const brands = data?.productBrands?.nodes ?? [];

  return (
    <>
      <div className="Brands">
        <Container className="p-0-mobile">
          <Row>
            <Col xs="12" md="3">
              <Sidebar className="Brands-sidebar">
                <BrandsSidebar
                  onSearchChange={handleSearchChange}
                  onSelectChange={handleSelectChange}
                />
              </Sidebar>
            </Col>
            <Col xs="12" md="9">
              <ShowOnMobileOnly>
                <BrandsSidebar
                  mobile={true}
                  onSearchChange={handleSearchChange}
                  onSelectChange={handleSelectChange}
                />
              </ShowOnMobileOnly>
              <ApolloErrorGuard error={error}>
                <BrandsList
                  loading={loading}
                  hasNextPage={hasNextPage}
                  brands={brands}
                  onLoadMore={handleLoadMore}
                  isLoadingMore={isLoadingMore}
                />
              </ApolloErrorGuard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
