import React from "react";
import { useRouter } from "../../../lib/hooks/useRouter";
import cs from "classnames";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

import "./BlockButton.scss";
import { Button } from "../Button/Button";

interface Props {
  type?: "button" | "submit" | "reset" | undefined;
  fill?: boolean;
  link?: boolean;
  to?: string;
  inverse?: boolean;
  disabled?: boolean;
  className?: string;
  children: string;
  onClick?: () => void;
}

export const BlockButton = (props: Props) => {
  const router = useRouter();
  const langCtx = useLanguageContext();
  const prefix = langCtx.getLangPrefix();

  return (
    <Button
      disabled={props.disabled}
      type={props.type || "button"}
      className={cs(
        "BlockButton",
        props.inverse && "BlockButton--inverse",
        props.fill && "BlockButton--fill",
        props.className
      )}
      onClick={() => {
        if (props.link && props.to) {
          router.history.push(prefix + props.to);
          return;
        }

        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.children}
    </Button>
  );
};
