import { ProductSingleVariationProps } from "../../../../queries/archive";

export const getVariationAttributeValue = (
  variation: ProductSingleVariationProps,
  attr_name: string
) => {
  return (
    variation.attributes.nodes.find(attr => attr.name === attr_name)?.value ||
    null
  );
};
