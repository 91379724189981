import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { useMenuContext } from "../../../../lib/context/MenuContext/MenuContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";

import "./MenuResponsive.scss";
import { useFetchMenu } from "../lib/useFetchMenu";
import { LoadingPlaceholder } from "../../../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { useQuery } from "react-apollo";
import { GET_BRANDS_LIST } from "../../../../queries/brandsQueries";

export const MenuResponsive = () => {
  const ref = useRef(null);
  const menuCtx = useMenuContext();
  const { categories, loading } = useFetchMenu();
  const brands = useQuery(GET_BRANDS_LIST, {
    variables: {
      limit: 100
    }
  });

  const [opened, setOpened] = useState(false);
  const [openedCategory, setOpenedCategory] = useState("");

  const [brand, setBrand] = useState(false);

  useOutsideClickDetection(ref, () => menuCtx.closeSidebar());

  const handleSelectCategory = (id: string) => {
    if (openedCategory === id) {
      setOpenedCategory("");
      return;
    }

    setOpenedCategory(id);
  };

  return (
    <div
      ref={ref}
      className={cn(
        "MenuResponsive",
        menuCtx.isOpened && "MenuResponsive__opened"
      )}
    >
      <span
        role="button"
        className="MenuResponsive__close"
        onClick={menuCtx.closeSidebar}
      >
        <Icon icon="x" />
      </span>

      <div className="MenuResponsive__list">
        <div
          data-root="true"
          className="MenuResponsive__list-item MenuResponsive__list-item--sale"
        >
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to="/sale">Në zbritje</NavLink>
          </div>
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to="/shop">Produktet</NavLink>
          </div>
        </div>
        <div
          data-root="true"
          className="MenuResponsive__list-item MenuResponsive__list-item--has-children"
        >
          <div
            data-opened={opened}
            className="MenuResponsive__list-item-toggle"
            onClick={() => setOpened(!opened)}
          >
            <span>Kategoritë</span>
            <Icon icon="chevron-down" className="chevron" />
          </div>

          {opened && (
            <>
              {loading ? (
                <LoadingPlaceholder />
              ) : (
                <>
                  {categories.map((category) => {
                    const selected = openedCategory === category.id;
                    return (
                      <div
                        key={category.id}
                        className={cn(
                          "MenuResponsive__list-item",
                          category?.children?.nodes &&
                            "MenuResponsive__list-item--has-children"
                        )}
                      >
                        <div
                          data-opened={selected}
                          className="MenuResponsive__list-item-toggle"
                        >
                          <NavLink to={`/shop/${category.slug}`}>
                            {category.name}
                          </NavLink>
                          <span
                            role="button"
                            onClick={() => handleSelectCategory(category.id)}
                          >
                            <Icon icon="chevron-down" className="chevron" />
                          </span>
                        </div>
                        {selected && (
                          <ul className="MenuResponsive__list-item-submenu">
                            {category?.children?.nodes.map((c) => (
                              <li key={c.id}>
                                <NavLink
                                  to={`/shop/${category.slug}/${c.slug}`}
                                >
                                  {c.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                </>
                // <ul className="MenuResponsive__list-item-submenu">
                //   <li>
                //     <NavLink to="/">TEST</NavLink>
                //   </li>
                //
                // </ul>
              )}
            </>
          )}
        </div>

        <div
          data-root="true"
          className="MenuResponsive__list-item MenuResponsive__list-item--has-children"
        >
          <div
            data-opened={brand}
            className="MenuResponsive__list-item-toggle"
            onClick={() => setBrand(!brand)}
          >
            <span>Brendet</span>
            <Icon icon="chevron-down" className="chevron" />
          </div>

          {brand && (
            <>
              {loading ? (
                <LoadingPlaceholder />
              ) : (
                <>
                  {brands?.data?.productBrands?.nodes.map((category: any) => {
                    const selected = openedCategory === category.id;
                    return (
                      <div
                        key={category.id}
                        className={cn("MenuResponsive__list-item")}
                      >
                        <div
                          data-opened={selected}
                          className="MenuResponsive__list-item-toggle"
                        >
                          <NavLink to={`/brands/${category.slug}`}>
                            {category.name}
                          </NavLink>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to="/blog">Blog</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
