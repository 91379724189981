import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { fields, messages } from "../../lib/translation/strings";

import FaqItem from "./FaqItem";

import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { BulkLoader } from "../../components/shared/BulkLoader/BulkLoader";
import { ProductSingleLoader } from "../ProductSingle/ProductSingleLoader";
import { FAQItem, FAQResponse, GET_FAQ } from "../../queries/generalQueries";
import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { HandleNoItemsState } from "../../components/shared/HandleNoItemsState/HandleNoItemsState";

import "./Faq.scss";


export const Faq = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const { loading, data, error } = useQuery<FAQResponse, undefined>(GET_FAQ);

  return (
    <div className="Faq">
      <ThemeContainer>
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <BulkLoader length={1} component={ProductSingleLoader} />
          }
        >
          <ApolloErrorGuard error={error}>
            <HandleNoItemsState
              hasItems={
                !!data?.generalOptions &&
                data.generalOptions.generalOptions.faq.list.length > 0
              }
              placeholder={
                <EmptyPage
                  title={t(fields.faq)}
                  description={t(messages.noFaq)}
                />
              }
            >
              <HeadingTitle title={t(fields.faq)} />
              <div className="Faq__list">
                {data?.generalOptions.generalOptions.faq.list?.map(
                  (item: FAQItem, index: number) => {
                    return (
                      <FaqItem
                        key={index}
                        title={item.title}
                        content={item.description}
                        itemID={`faq_item_${index}`}
                        activeTab={activeTab}
                        onClick={(id: string) => setActiveTab(id)}
                      />
                    );
                  }
                )}
              </div>
            </HandleNoItemsState>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </ThemeContainer>
    </div>
  );
};
