import React, { useState } from "react";
import cs from "classnames";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { useShopHandlers } from "../../../lib/hooks/useShopHandlers";
import { useSearchParams } from "../../../lib/hooks/useSearchParams";

import { Select } from "../../GeneralFilters/SortSelect/Select";
import { SearchInput } from "../../GeneralFilters/SearchInput/SearchInput";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";

import { fields } from "../../../lib/translation/strings";

import * as brandQueries from "../../../queries/brands";

import "./BrandsSidebar.scss";

interface Props {
  mobile?: boolean;
  className?: string;
  onSearchChange: (value: string) => void;
  onSelectChange: (value: string) => void;
}

export const BrandsSidebar = (props: Props) => {
  const { t } = useTranslation();
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();

  const { loading, data, error } = useQuery<
    brandQueries.GetBrandsLettersResponse
  >(brandQueries.getBrandsLetters);

  const [search, setSearch] = useState("");

  const handleSearchChange = (value: string) => {
    setSearch(value);
    props.onSearchChange(value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    shopHandlers.updateSingleQueryParameter(
      "firstLetter",
      e.target.value,
      (field, value) => {
        props.onSelectChange(value);
      }
    );
  };

  const brandsLetters = data?.productBrands?.brandsLetters?.letters ?? [];

  const options = brandsLetters.map((letter) => ({
    value: letter.toUpperCase(),
    label: letter.toUpperCase()
  }));

  return (
    <div
      className={cs(
        "BrandsSidebar",
        props.mobile && "BrandsSidebar-mobile",
        props.className
      )}
    >
      <h4 className="BrandsSidebar__title">{t(fields.categorizeByAlphabet)}</h4>

      <SearchInput
        value={search}
        name="brands-search"
        className="BrandsSidebar__search"
        onChange={handleSearchChange}
      />

      <ApolloErrorGuard error={error}>
        <Select
          value={
            searchParams.firstLetter ? (searchParams.firstLetter as string) : ""
          }
          loading={loading}
          name="order-brands"
          options={[
            { value: "", label: t(fields.viewAll), isPlaceholder: true },
            ...options
          ]}
          onChange={handleSelectChange}
        />
      </ApolloErrorGuard>
    </div>
  );
};
