import { ProductPricesType } from "../context/CartContext/CartContext";
import { CartNode } from "../../queries/cartQueries";
import { CartProduct } from "../../types/cartTypes";

export const getProductPricesN = (
  item: CartProduct | CartNode
): ProductPricesType => {
  const hasVariations = item.product.node.type === "VARIABLE";

  return {
    sale: hasVariations
      ? item.variation?.node?.salePrice
      : item.product.node.salePrice,
    regular: hasVariations
      ? item.variation?.node?.regularPrice
      : item.product.node.regularPrice
  };
};
