import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import { errors } from "../../lib/translation/strings";

import { ProductItem } from "./ProductItem";
import { ProductsLoader } from "./ProductsLoader";
import { BulkLoader } from "../shared/BulkLoader/BulkLoader";
import { LoadMoreButton } from "../shared/LoadMoreButton/LoadMoreButton";
import { InfoMessage } from "../shared/InfoMessage/InfoMessage";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";

import { Product } from "../../types/productTypes";

import "./ProductsList.scss";

interface Props {
  big?: boolean;
  className?: string;
  error?: string;
  loading?: boolean;
  products?: Product[];
  isLoading: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  pageInfo: boolean;
  loadingPlaceholderNumber?: number;

  onLoadPrevious?: () => void;
  onLoadMore: () => void;
}

export const ProductsList = (props: Props) => {
  const { t } = useTranslation();
  const noProductsAvailable =
    !props.products || Number(props.products.length) === 0;

  return (
    <>
      {props.hasPreviousPage && (
        <div className="mb-4">
          <LoadMoreButton
            btnLabel="Load previous"
            loading={props.loading || props.isLoading}
            onClick={() => {
              if (props.onLoadPrevious) {
                props.onLoadPrevious();
              }
            }}
          />
        </div>
      )}

      <div
        className={cs(
          "ProductsList",
          !props.loading && noProductsAvailable && "ProductsList--empty",
          props.className
        )}
      >
        <HandleLoadingState
          loading={props.loading}
          loadingPlaceholder={
            <BulkLoader
              length={props.loadingPlaceholderNumber || 6}
              component={ProductsLoader}
            />
          }
        >
          {noProductsAvailable ? (
            <InfoMessage
              title={t(errors.noProducts)}
              description={t(errors.noProductsDescription)}
              buttonLink="/shop"
            />
          ) : (
              props.products?.map((product, index: number) => {
                return (
                  <ProductItem
                    key={product.id}
                    product={product}
                  />
                );
              })
            )}
        </HandleLoadingState>
      </div>
      {props.pageInfo && (
        <LoadMoreButton
          onClick={props.onLoadMore}
          loading={props.loading || props.isLoading}
        />
      )}
    </>
  );
};
