import React from "react";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { UserCart } from "./UserCart";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useMenuSidebar } from "../../../lib/context/MenuSidebarContext/MenuSidebarContext";
import "./UserNavigation.scss";

export const UserNavigation = () => {
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();

  return (
    <div className="UserNavigation d-flex">
      {/*<span*/}
      {/*  role="button"*/}
      {/*  className="UserNavigation__item UserNavigation__item--search"*/}
      {/*>*/}
      {/*  <span role="button">*/}
      {/*    <Icon icon="search" />*/}
      {/*  </span>*/}
      {/*</span>*/}

      <div className="UserNavigation__item UserNavigation__item--login">
        {authCtx.isAuthenticated ? (
          <ThemeLink
            to="/my-profile"
            activeClassName="UserNavigation__item--active"
          >
            <span className="UserNavigation__item--wishlist-icon">
              <Icon icon="user" />
            </span>
            <span className="UserNavigation__item__label">
              <span className="UserNavigation__item--wishlist-name">
                {authCtx?.user?.firstName.toLocaleLowerCase()}{" "}
                {authCtx?.user?.lastName.toLocaleLowerCase()}
              </span>
            </span>
          </ThemeLink>
        ) : (
          <ThemeLink to="/login" activeClassName="UserNavigation__item--active">
            <span className="UserNavigation__item--wishlist-icon">
              <Icon icon="user" />
            </span>
            <span className="UserNavigation__item__label">Kyçu</span>
          </ThemeLink>
        )}
      </div>

      <span
        role="button"
        onClick={() => menuSidebar.openSidebar("wishlist")}
        className="UserNavigation__item UserNavigation__item--wishlist"
      >
        <span className="UserNavigation__item--wishlist-icon">
          <Icon icon="heart" />
        </span>
        <span className="UserNavigation__item__label">Lista e deshirave</span>
      </span>

      <UserCart className="UserNavigation__item" />

      {authCtx.isAuthenticated && (
        <span
          className="UserNavigation__item UserNavigation__item--wishlist"
          role="button"
          onClick={() => authCtx.logout()}
        >
          <span role="button">
            <Icon icon="log-out" />
          </span>
          <span className="UserNavigation__item__label">Dil</span>
        </span>
      )}
    </div>
  );
};
