import React from "react";
import { Container } from "reactstrap";
import { Logo } from "../Logo/Logo";
import { Search } from "../Search/Search";
import { UserNavigation } from "../UserNavigation/UserNavigation";
import "./TopHeader.scss";

export const TopHeader = () => {
  return (
    <div className="TopHeader">
      <Container>
        <div className="TopHeader__wrapper">
          <Logo />
          <Search />
          <UserNavigation />
        </div>
      </Container>
    </div>
  );
};
