import gql from "graphql-tag";

export interface CheckoutInputData {
  clientMutationId: string;
  billing: {
    address1: string;
    city: string;
    email: string;
    country: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  shipping?: {
    country: string;
    address1: string;
    city: string;
    firstName: string;
    lastName: string;
  };
  account?: {
    username: string;
    password: string;
  };
  customerNote?: string;
  paymentMethod: string;
  shippingMethod: string;
  shipToDifferentAddress: boolean;
}

export interface CheckoutVariables {
  inputData: CheckoutInputData;
}

export interface CheckoutResponse {
  checkout: {
    order: {
      id: string;
      databaseId: number;
    };
    customer: {
      jwtAuthToken: string;
      jwtRefreshToken: string;
      id: string;
      email: string;
      databaseId: number;
      lastName: string;
      firstName: string;
      username: string;
    };
  };
}

export const CHECKOUT_ORDER = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      order {
        id
        databaseId
      }
      customer {
        jwtAuthToken
        jwtRefreshToken
        id
        email
        databaseId
        lastName
        firstName
        username
      }
    }
  }
`;
