import React from "react";
import { Container } from "reactstrap";

import { HomepageSlide } from "../../components/home/HomepageSlide/HomepageSlide";
import { HomeTabs } from "../../components/home/HomeTabs/HomeTabs";
import { HomeBlog } from "../../components/home/HomeBlog/HomeBlog";
import { Newsletter } from "../../components/home/Newsletter/Newsletter";

import "./Home.scss";
// import WelcomeModal from "../../components/shared/WelcomeModal/WelcomeModal";

export const Home = () => {
  return (
    <div className="Home">
      {/* <WelcomeModal /> */}
      <HomepageSlide />
      <Container>
        <HomeTabs />
        <HomeBlog />
      </Container>
      <Newsletter />
    </div>
  );
};
