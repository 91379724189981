import React from "react";
import { Container } from "reactstrap";
// import ContentLoader from "react-content-loader";
import { RouteComponentProps } from "react-router-dom";

import "./CheckoutConfirmation.scss";

interface Props {
  orderId: string;
}

export const CheckoutConfirmation = (props: RouteComponentProps<Props>) => {
  return (
    <div className="CheckoutConfirmation">
      <Container>
        <div className="CheckoutConfirmation__head">
          <h1 className="mb-2">
            <span role="img" aria-label="tada">
              🎉
            </span>
          </h1>
          <h1>Konfirmimi i porosisë</h1>
          <p>
            Porosia juaj është duke u procesuar, ju do të njoftoheni përmes
            email tuaj për detajet tjera!
          </p>
        </div>
      </Container>
    </div>
  );
};
