import React from 'react';
import ContentLoader from "react-content-loader";

import "./EventsItem.scss";

export const EventsItemLoader = () => {
    return (
        <div className="EventsItemLoader">
            <ContentLoader
                speed={2}
                width={300}
                height={210}
                viewBox="0 0 300 210"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                className="EventsItemLoader"
            >
                <rect x="0" y="0" rx="2" ry="2" width="300" height="210"/>
            </ContentLoader>
        </div>
    );
};