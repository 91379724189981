import React, { useState } from "react";
import cs from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ShopLayout } from "../../components/Shop/ui/ShopLayout";
import { ShopContextProvider } from "../../lib/context/ShopContext/ShopContextProvider";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { useOrderFieldsFromSlug } from "../../lib/hooks/useOrderFieldsFromSlug";
import { ShopSidebar } from "../../components/Shop/ShopSidebar/ShopSidebar";
import { Image } from "../../components/shared/Image/Image";

import * as brandQueries from "../../queries/brands";
import "./ShopByBrand.scss";
import { ShopLogoPlaceholder } from "../../components/Shop/ui/ShopLogoPlaceholder";
import { ButtonFilterMobile } from "../../components/GeneralFilters/ButtonFilterMobile/ButtonFilterMobile";

const postsLimit = 15;
const defaultField = "DATE";
const defaultOrder = "ASC";

interface Props {
  brandSlug: string;
  parentCategory?: string;
  childCategory?: string;
}

export const ShopByBrand = (props: RouteComponentProps<Props>) => {
  const searchParams = useSearchParams();
  const shopHandlers = useShopHandlers();
  const orderFields = useOrderFieldsFromSlug();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { childCategory, parentCategory, brandSlug } = props.match.params;

  const {
    data: brandData,
    loading: brandLoading,
    error: brandError
  } = useQuery<
    brandQueries.GetBrandBySlugResponse,
    brandQueries.GetBrandBySlugVariables
  >(brandQueries.getBrandBySlug, {
    variables: {
      slug: brandSlug
    }
  });

  const { loading, data, error, fetchMore, refetch } = useQuery<
    brandQueries.GetBrandProductsResponse,
    brandQueries.GetBrandProductsVariables
  >(brandQueries.getBrandProducts, {
    variables: {
      after: "",
      slug: props.match.params.brandSlug,
      category:
        childCategory ||
        (parentCategory !== "undefined" && parentCategory) ||
        "",
      limit: postsLimit,
      field: orderFields.field,
      order: orderFields.order,
      taxonomyFilter: shopHandlers.prepareTaxonomyFilters(searchParams)
    }
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        slug: props.match.params.brandSlug,
        limit: postsLimit,
        after: data?.products?.pageInfo?.endCursor
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo
          }
        };
      }
    });
  };

  const handleOrderChange = (value: string) => {
    shopHandlers.updateSingleQueryParameter("order", value, (field, order) => {
      refetch({
        slug: props.match.params.brandSlug,
        limit: postsLimit,
        field: field || defaultField,
        order: order || defaultOrder
      });
    });
  };

  const handleAttributeChange = (data: any) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);
    refetch({
      slug: props.match.params.brandSlug,
      limit: postsLimit,
      taxonomyFilter: taxonomyFilters
    });
  };

  const handleCategoryChange = (categorySlug: string) => {
    refetch({
      slug: props.match.params.brandSlug,
      limit: postsLimit,
      category: categorySlug,
      taxonomyFilter: []
    });
  };

  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const products = data?.products?.nodes;
  const noProductsAvailable = !products || Number(products.length) === 0;

  const brandName = brandData?.productBrands?.nodes[0]?.name ?? "";
  const brandLogo =
    brandData?.productBrands?.nodes[0]?.brandData?.logo?.sourceUrl;

  return (
    <ShopContextProvider
      data={{
        data: {
          shopType: "brand",
          shopLinkPrefix: `brands/${props.match.params.brandSlug}`,
          slug: props.match.params.brandSlug,
          category: parentCategory,
          subCategory: childCategory,
          order: `${orderFields.field}-${orderFields.order}`
        },
        handlers: {
          handleCategoryChange,
          handleAttributeChange,
          handleOrderChange
        }
      }}
    >
      <ShopLayout
        className={cs(
          "ShopByBrand",
          noProductsAvailable && "ShopLayout--no-products"
        )}
        head={
          <HandleLoadingState
            loading={brandLoading}
            loadingPlaceholder={<ShopLogoPlaceholder />}
          >
            <ApolloErrorGuard error={brandError}>
              {!brandLogo ? (
                <h1>{brandName}</h1>
              ) : (
                <Image style={{ width: 170 }} src={brandLogo} alt={brandName} />
              )}
            </ApolloErrorGuard>
          </HandleLoadingState>
        }
        sidebar={<ShopSidebar className="ShopByBrand__sidebar" />}
        content={
          <ApolloErrorGuard error={error}>
            <ButtonFilterMobile
              className={cs("ShopFilterActions__btn ShopFilterActions__filter")}
            />

            <ProductsList
              big
              loadingPlaceholderNumber={postsLimit}
              loading={loading}
              error={error?.message}
              products={products}
              isLoading={isLoadingMore}
              pageInfo={hasNextPage}
              onLoadMore={handleLoadMore}
            />
          </ApolloErrorGuard>
        }
      />
    </ShopContextProvider>
  );
};
