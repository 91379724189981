import React from "react";
import { TopHeader } from "./TopHeader/TopHeader";
import { Menu } from "./Menu/Menu";
import { MenuResponsive } from "./Menu/MenuResponsive/MenuResponsive";
import { MenuContextProvider } from "../../lib/context/MenuContext/MenuContextProvider";

import "./Header.scss";
import { Search } from "./Search/Search";

export const Header = () => {
  return (
    <div className="Header">
      <MenuContextProvider>
        <div className="Header__wrapper">
          <TopHeader />
          <Menu />
        </div>
        <MenuResponsive />
        <Search className="Header-search-mobile" />
      </MenuContextProvider>
    </div>
  );
};
