import React from "react";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";
import { useMenuContext } from "../../../lib/context/MenuContext/MenuContext";

import "./Logo.scss";

interface Props {
  noLink?: boolean;
}

export const Logo = (props: Props) => {
  const menuCtx = useMenuContext();

  return (
    <div className="Logo">
      <span
        role="button"
        className="Logo__mobile-menu"
        onClick={menuCtx.openSidebar}
      >
        <Icon icon="menu" />
      </span>
      {props.noLink ? (
        <LogoIcon className="Logo__svg" />
      ) : (
        <ThemeLink to="/">
          <LogoIcon className="Logo__svg" />
        </ThemeLink>
      )}
    </div>
  );
};
