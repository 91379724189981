import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";

import { fields, messages } from "../../lib/translation/strings";

import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { DefaultButton } from "../../components/shared/DefaultButton/DefaultButton";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";

import "./Verification.scss";

interface Props {
  title?: string;
  desc?: string;
  buttonLink?: string;
  buttonTitle?: string;
}

export const Verification = (props: Props) => {
  const {t} = useTranslation();

  return (
    <div className="Verification">
      <Container>
        <HeadingTitle title={props.title || t(fields.verification)} />
        <ThemeContainer>
          <div className="Verification__description">
            <p>
              {props.desc || t(messages.orderConfirmed)}
            </p>
          </div>
          <DefaultButton
            to={props.buttonLink || "/"}
            title={props.buttonTitle || messages.allRight}
          />
        </ThemeContainer>
      </Container>
    </div>
  );
};
