import React from "react";
import { Container } from "reactstrap";
import { Icon } from "../../components/shared/Icon/Icon";
import { NavLink } from "react-router-dom";

import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="Footer">
      <Container>
        <div className="Footer__items">
          <p className="Footer__items--text">
          L'kurë është kompania e parë që sjellë brendet e njohura të K Beauty në tregun kosovar. K Beauty është koncept korean për përkujdesjen ndaj lëkurës, që bazohet në produkte natyrale.  
          </p>
          <div className="Footer__items__right">
            <ul className="Footer__menu">
              <li>
                <NavLink to="/transport">Transporti</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Kontakti</NavLink>
              </li>
              <li>
                <NavLink to="/kushtet-e-blerjes">Kushtet e blerjes</NavLink>
              </li>
              <li>
                <NavLink to="/politikat-e-privatesise">
                  Politikat e privatsise
                </NavLink>
              </li>
            </ul>
            <ul className="Footer__social-media">
              <li>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="Footer__copyright">
        <span>Copyright © L'kurë {new Date().getFullYear()}</span>
      </div>
    </div>
  );
};
