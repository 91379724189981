import gql from 'graphql-tag';

export const SUBSCRIBE = gql`
  mutation subscribe($clientMutationId: String!, $email: String!) {
    mailchimpSubscription(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      message
    }
  }
`;

export const SUBSCRIBE_COUPON = gql`
  mutation subscribeCoupon($clientMutationId: String!, $email: String!) {
    mailchimpCouponSubscription(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      message
    }
  }
`;
