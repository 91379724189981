import React from "react";
import { useQuery } from "react-apollo";

import { useRouter } from "../../lib/hooks/useRouter";

import { DefaultPage } from "../DefaultPage/DefaultPage";
import { GET_EVENT_POST } from "../../queries/eventsQueries";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { Col, Container, Row } from "reactstrap";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

export const EventSingle = () => {
  const router: any = useRouter();
  const { data, loading, error } = useQuery(GET_EVENT_POST, {
    variables: {
      slug: router.match.params?.slug
    }
  });

  return (
    <div className="EventSingle">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <Container>
            <Row>
              <Col
                xs="12"
                md="12"
                className="d-flex justify-content-center mt-5"
              >
                <LoadingPlaceholder />
              </Col>
              {/*<Col xs="12" md="6">*/}
              {/*  <ProductSingleLoader />*/}
              {/*</Col>*/}
            </Row>
          </Container>
        }
      >
        <ApolloErrorGuard error={error}>
          <DefaultPage
            content={data?.postBy?.content}
            title={data?.postBy?.title}
            excerpt={data?.postBy?.excerpt}
            featuredImage={data?.postBy?.featuredImage.node.sourceUrl}
          />
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
