import React from "react";
import { useTranslation } from "react-i18next";
import { actions } from "../../../lib/translation/strings";

import { BlockButton } from "../BlockButton/BlockButton";

import "./LoadMoreButton.scss";

interface Props {
  loading: boolean;
  onClick: () => void;

  btnLabel?: string;
}

export const LoadMoreButton = (props: Props) => {
  const { t } = useTranslation();
  
  return (
    <BlockButton
      className="LoadMoreButton"
      disabled={props.loading}
      onClick={props.onClick}
    >
      {props.loading
        ? t(actions.loading)
        : t(props.btnLabel || actions.seeMore)}
    </BlockButton>
  );
};
