import React from "react";
import { FormGroup, Label, Input, FormFeedback, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { SelectCity } from "../../shared/SelectCity/SelectCity";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";
import { fields, formInputs } from "../../../lib/translation/strings";

interface Props {
  formik: CheckoutFormik;
}

export const ShippingFields = (props: Props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <>
      <Row>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="shippingFirstName">{t(formInputs.name)}</Label>
            <Input
              type="text"
              id="shippingFirstName"
              name="shipping.firstName"
              value={formik.values.shipping.firstName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.shipping?.firstName &&
                formik.touched.shipping?.firstName
              }
            />
            <FormFeedback>{formik.errors.shipping?.firstName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="shippingLastName">{t(formInputs.lastName)}</Label>
            <Input
              type="text"
              id="shippingLastName"
              name="shipping.lastName"
              value={formik.values.shipping.lastName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.shipping?.lastName &&
                formik.touched.shipping?.lastName
              }
            />
            <FormFeedback>{formik.errors.shipping?.lastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="shippingAddress">{t(formInputs.address)}</Label>
            <Input
              type="text"
              id="shippingAddress"
              name="shipping.address1"
              value={formik.values.shipping.address1}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.shipping?.address1 &&
                formik.touched.shipping?.address1
              }
            />
            <FormFeedback>{formik.errors.shipping?.address1}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="billingCity">{t(fields.city)}</Label>
            <SelectCity
              name="shipping.city"
              initialValue={formik.values.shipping.city}
              hasError={
                !!formik.errors.shipping?.city && formik.touched.shipping?.city
              }
              errorMessage={formik.errors.shipping?.city}
              onSelect={(city: string) => {
                formik.setFieldValue("shipping", {
                  ...formik.values.shipping,
                  city
                });
                formik.setFieldTouched("shipping");
              }}
              onComplete={(loading) => {
                return;
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
