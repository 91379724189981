import gql from "graphql-tag";

export interface ProductArchiveItem {
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  regularPrice: string;
  salePrice: string;
  image: {
    srcSet: string;
    mediaItemUrl: string;
  };
}

export interface CustomAttributeOption {
  id: number;
  slug: string;
  name: string;
}

export interface ProductSingleAttributeProps {
  name: string;
  options: string[];
  id: string;
  scope: string;
  visible: boolean;
  customAttributesOptions: CustomAttributeOption[];
  enabled_options?: (string | null)[];
}
export interface ProductSingleVariationProps {
  id: string;
  variationId: number;
  regularPrice: string;
  salePrice: string;
  sku: string;
  onSale: boolean;
  stockQuantity: number;
  image?: {
    slug?: string;
  };
  attributes: {
    nodes: {
      name: string;
      value: string;
    }[];
  };
}

export interface ProductOptionTab {
  title: string;
  description: string;
}

export interface ProductOptions {
  howToUse?: ProductOptionTab[];
  ingridients?: ProductOptionTab;
  benefits?: ProductOptionTab;
  skintype?: ProductOptionTab;
  qa?: ProductOptionTab;
}

export interface ProductSingleProps {
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  description: string;
  onSale: boolean;
  sku: string;
  type: string;
  regularPrice: string;
  salePrice: string;
  stockQuantity: number;
  image: {
    slug?: string;
    sourceUrl: string;
  };
  galleryImages: {
    nodes: {
      slug?: string;
      sourceUrl: string;
    }[];
  };
  productCategories: {
    nodes: {
      slug: string;
    }[];
  };
  productBrands: {
    nodes: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
  productOptions: ProductOptions;
  attributes: {
    nodes: ProductSingleAttributeProps[];
  };
  variations: {
    nodes: ProductSingleVariationProps[];
  };
}
export const GET_ARCHIVE_PRODUCTS = gql`
  query archiveQuery($limit: Int, $after: String) {
    products(first: $limit, after: $after) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
  query archiveQuery($exclude: [Int], $category: String) {
    products(where: { exclude: $exclude, category: $category }, first: 8) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;

export const GET_SINGLE_PRODUCTS = gql`
  query singleProduct($slugIn: [String]) {
    products(where: { slugIn: $slugIn }) {
      nodes {
        id
        slug
        databaseId
        name
        description
        onSale
        sku
        type
        image {
          id
          sourceUrl(size: MEDIUM_LARGE)
        }
        galleryImages {
          nodes {
            id
            sourceUrl(size: LARGE)
          }
        }
        productCategories {
          nodes {
            id
            slug
          }
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        attributes {
          nodes {
            name
            options
            id
            scope
            visible
            customAttributesOptions {
              id
              slug
              name
            }
          }
        }
        productOptions {
          howToUse {
            description
            title
          }
          ingridients {
            description
            title
          }
          benefits {
            description
            title
          }
          skintype {
            description
            title
          }
          qa {
            description
            title
          }
        }
        ... on SimpleProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          variations(first: 1000) {
            nodes {
              id
              databaseId
              regularPrice(format: FORMATTED)
              salePrice(format: FORMATTED)
              sku
              onSale
              stockQuantity
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_WISHLIST = gql`
  query getUserWishlist($products: [Int]) {
    products(first: 100, where: { include: $products }) {
      nodes {
        id
        slug
        name
        databaseId
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: SHOP_CATALOG)
        }
      }
    }
  }
`;

export const GET_SEARCH_QUERY = gql`
  query getSearchQuery($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
