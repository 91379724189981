import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import cs from "classnames";
import { useQuery } from "react-apollo";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { ShopLayout } from "../../components/Shop/ui/ShopLayout";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { ShopSidebar } from "../../components/Shop/ShopSidebar/ShopSidebar";
import { ShopContextProvider } from "../../lib/context/ShopContext/ShopContextProvider";
import { useOrderFieldsFromSlug } from "../../lib/hooks/useOrderFieldsFromSlug";
import { useInitialSearchParams } from "../../lib/hooks/useInitialSearchParams";

import * as shopQueries from "../../queries/shop";
import "./ShopByCategory.scss";
import { ButtonFilterMobile } from "../../components/GeneralFilters/ButtonFilterMobile/ButtonFilterMobile";

const postsLimit = 15;
const defaultField = "DATE";
const defaultOrder = "ASC";

export interface Props {
  parentCategory?: string;
  childCategory?: string;
}

export const ShopByCategory = (props: RouteComponentProps<Props>) => {
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const orderFields = useOrderFieldsFromSlug();
  const initialSearchParams = useInitialSearchParams();

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [, /*isLoadingPrevious*/ setIsLoadingPrevious] = useState(false);

  const { childCategory, parentCategory } = props.match.params;

  const { loading, data, error, fetchMore, refetch } = useQuery<
    shopQueries.GetProductsResponse,
    shopQueries.GetProductsVariables
  >(shopQueries.getProducts, {
    fetchPolicy: "cache-first",
    variables: {
      before: "",
      after:
        initialSearchParams && initialSearchParams.page
          ? (initialSearchParams.page as string)
          : "",
      limit: postsLimit,
      category: childCategory || parentCategory || "",
      field: orderFields.field,
      order: orderFields.order,
      taxonomyFilter: shopHandlers.prepareTaxonomyFilters(searchParams),
      onSale: false
    }
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.products?.pageInfo?.endCursor
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        // if (pageInfo.endCursor) {
        //   shopHandlers.updateSingleQueryParameter(
        //     "page",
        //     previousResult?.products?.pageInfo?.endCursor,
        //     () => {}
        //   );
        // }

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              endCursor: pageInfo?.endCursor,
              hasNextPage: pageInfo?.hasNextPage
            }
          }
        };
      }
    });
  };

  const handleLoadPrevious = () => {
    setIsLoadingPrevious(true);
    fetchMore({
      variables: {
        last: postsLimit,
        first: undefined,
        limit: undefined,
        after: undefined,
        before: data?.products?.pageInfo?.startCursor
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        setIsLoadingPrevious(false);
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...newNodes, ...previousResult.products.nodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              startCursor: pageInfo?.startCursor,
              hasPreviousPage: pageInfo?.hasPreviousPage
            }
          }
        };
      }
    });
  };

  const handleOrderChange = (value: string) => {
    shopHandlers.updateSingleQueryParameter("order", value, (field, order) => {
      refetch({
        limit: postsLimit,
        field: field || defaultField,
        order: order || defaultOrder
      });
    });
  };

  const handleCategoryChange = (categorySlug: string) => {
    refetch({
      limit: postsLimit,
      category: categorySlug,
      taxonomyFilter: []
    });
  };

  const handleAttributeChange = (data: any) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);
    refetch({
      limit: postsLimit,
      taxonomyFilter: taxonomyFilters
    });
  };

  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const hasPreviousPage = !!data?.products?.pageInfo?.hasPreviousPage;
  const products = data?.products?.nodes;
  const noProductsAvailable = !products || Number(products.length) === 0;

  return (
    <ShopContextProvider
      data={{
        data: {
          shopType: "category",
          shopLinkPrefix: "shop",
          category: parentCategory,
          subCategory: childCategory,
          order: `${orderFields.field}-${orderFields.order}`
        },
        handlers: {
          handleCategoryChange,
          handleAttributeChange,
          handleOrderChange
        }
      }}
    >
      <ShopLayout
        className={cs(
          "ShopByCategory",
          noProductsAvailable && "ShopLayout--no-products"
        )}
        sidebar={<ShopSidebar className="ShopByCategory__sidebar" />}
        content={
          <ApolloErrorGuard error={error}>
            <ButtonFilterMobile
              className={cs("ShopFilterActions__btn ShopFilterActions__filter")}
            />
            <ProductsList
              big
              className="ShopByCategory__products"
              loadingPlaceholderNumber={postsLimit}
              loading={loading}
              error={error?.message}
              products={products}
              isLoading={isLoadingMore}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              pageInfo={hasNextPage}
              onLoadPrevious={handleLoadPrevious}
              onLoadMore={handleLoadMore}
            />
          </ApolloErrorGuard>
        }
      />
    </ShopContextProvider>
  );
};
