import React from "react";
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { useAuthContext } from "./lib/context/AuthContext/AuthContext";

import { NotificationContextProvider } from "./lib/context/NotificationContext/NotificationContextProvider";

import { Routes } from "./routes/Routes";
import { Header } from "./layout/Header/Header";
import { Footer } from "./layout/Footer/Footer";
import { LoadingPlaceholder } from "./components/shared/LoadingPlaceholder/LoadingPlaceholder";

import "./App.scss";
import { useMenuSidebar } from "./lib/context/MenuSidebarContext/MenuSidebarContext";
import { WishlistSidebar } from "./layout/WishlistSidebar/WishlistSidebar";
import { CartSidebar } from "./layout/CartSidebar/CartSidebar";

export const App = () => {
  useScrollToTop();
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();

  const handleOnClick = () => {
    menuSidebar.closeSidebar("wishlist");
    menuSidebar.closeSidebar("cart");
  };

  if (authCtx.isLoading) {
    return (
      <div className="App App--loading">
        <LoadingPlaceholder />
      </div>
    );
  }

  return (
    <div className="App" id="App">
      <div className="App__backdrop" onClick={handleOnClick} />
      <Header />
      <div className="App__main_content">
        <NotificationContextProvider>
          <Routes />
        </NotificationContextProvider>
      </div>
      <Footer />

      <WishlistSidebar />
      <CartSidebar />
    </div>
  );
};
