import { fromPromise } from "apollo-link";
import { onError } from "apollo-link-error";
import { setLocalValue } from "../lib/helpers/localStorageHelpers";
import { getNewToken } from "./apolloHelpers";

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!graphQLErrors) {
    return;
  }
  let hasExpiredTokenError = false;
  for (let err of graphQLErrors) {
    let errM = err as any;
    if (
      errM.debugMessage &&
      errM.debugMessage.indexOf("Expired token") >= 0 &&
      !hasExpiredTokenError
    ) {
      hasExpiredTokenError = true;
      return fromPromise(
        getNewToken().catch((e) => {
          localStorage.removeItem("LKURE_USER");
          window.location.reload();
          return;
        })
      ).flatMap((accessToken): any => {
        if (accessToken && accessToken.length > 0) {
          setLocalValue("authToken", accessToken);
        }
        return forward(operation);
      });
    }
  }
});
