import React, { useState, useEffect } from "react";
import cn from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import {
  GET_SINGLE_PRODUCTS,
  ProductSingleProps,
  ProductSingleAttributeProps,
  ProductSingleVariationProps,
  CustomAttributeOption
} from "../../queries/archive";
import {
  actions,
  errors,
  fields,
  messages
} from "../../lib/translation/strings";
import { ProductSingleLoader } from "./ProductSingleLoader";
import { Quantity } from "../../components/shared/QuantityInput/Quantity";
import { ProductPrices } from "../../components/shared/ProductPrices/ProductPrices";
import { Alert } from "../../components/Notifications/Alert";
import { RelatedProducts } from "../../components/RelatedProducts/RelatedProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { ProductSingleSlider } from "../../components/ProductSingleSlider/ProductSingleSlider";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ProductSingleSliderLoader } from "../../components/ProductSingleSlider/ProductSingleSliderLoader";
import { ProductSingleShareButton } from "./ProductSingleShareButton";
import { ProductSingleTabs } from "../../components/ProductSingle/ProductSingleTabs/ProductSingleTabs";

import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { useProductSingleHelpers } from "./lib/useProductSingleHelpers";

import "./ProductSingle.scss";
import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";

export const ProductSingle = (props: RouteComponentProps<{ slug: string }>) => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();
  const windowWidth = useWindowWidth();
  const notification = useNotification();
  const {
    validateAddToCartProduct,
    getVariationAttributeValue,
    getFirstVariationWithQuantity,
    getStockQuantity,
    getProductPrices,
    getProductVariationSlug
  } = useProductSingleHelpers();

  const [quantity, setQuantity] = useState(1);

  const [filteredVariations, setfilteredVariations] = useState<
    Array<ProductSingleVariationProps>
  >([]);

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string | null;
  }>({});

  const [filters, setfilters] = useState<Array<ProductSingleAttributeProps>>(
    []
  );

  const products = useQuery(GET_SINGLE_PRODUCTS, {
    variables: {
      slugIn: [props?.match?.params?.slug]
    }
  });

  const product: ProductSingleProps | undefined =
    products.data && products.data.products.nodes.length > 0
      ? products.data.products.nodes[0]
      : undefined;

  useEffect(() => {
    //reset state
    setQuantity(1);
    setfilteredVariations([]);
    setSelectedFilters({});
    setfilters([]);
  }, [props.match.params?.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (windowWidth <= 992 && cartCtx.cartUiState.productAdded) {
      notification.success(t(messages.productAdded));
    }
  }, [cartCtx.cartUiState.productAdded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (products.loading || !product?.attributes?.nodes) {
      return;
    }

    const productAttributes =
      product?.attributes?.nodes?.filter((n) => n.visible) || [];

    const firstVariationWithQuantity = getFirstVariationWithQuantity(product);

    let slFilters: any = {};
    const attributeFilters: ProductSingleAttributeProps[] = productAttributes
      .filter(
        (attr) =>
          attr.options && attr.options.length >= 1 && attr.name !== "pa_mosha"
      )
      .map((attr: ProductSingleAttributeProps) => {
        const variationAttribute = firstVariationWithQuantity?.attributes?.nodes.find(
          (node) => {
            return node.name === attr.name.toLowerCase();
          }
        );

        slFilters[attr.name.toLowerCase()] = variationAttribute?.value;
        let options = attr.customAttributesOptions
          ? attr.customAttributesOptions.map(
              (customAttr: CustomAttributeOption) => customAttr.name
            )
          : [];
        return {
          ...attr,
          options,
          enabled_options: [...options]
        };
      });
    if (product.type === "SIMPLE") {
      setfilters(productAttributes);
    } else {
      setfilters(attributeFilters);
      setSelectedFilters(slFilters);
    }

    if (firstVariationWithQuantity) {
      setfilteredVariations([firstVariationWithQuantity]);
    }
  }, [!products.loading && products.data && props.match.params?.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  // handlers
  const onSelectFilter = (selectedFilterName: string, filterValue: string) => {
    notification.removeAll();
    setQuantity(1);

    selectedFilters[selectedFilterName] = filterValue;
    setSelectedFilters(selectedFilters);

    if (
      selectedFilters[selectedFilterName] === undefined ||
      selectedFilters[selectedFilterName] === filterValue
    ) {
      return;
    }

    const productVariations = product?.variations.nodes || [];

    //filter variations based on selected attributes
    const filteredVariations = productVariations.filter(
      (variation: ProductSingleVariationProps) => {
        const hasDifferentAttributeValue = Object.keys(selectedFilters)
          .filter((key) => selectedFilters[key])
          .find(
            (key) =>
              getVariationAttributeValue(variation, key) !==
              selectedFilters[key]
          );

        return !hasDifferentAttributeValue;
      }
    );

    setfilteredVariations(filteredVariations);

    filters.map((filter: ProductSingleAttributeProps, index: number) => {
      // set the enabled_options for each filter based on the filteredVariations
      // Don't touch the current filter if it is becoming active - add to if to disable (&& filter.name === selectedFilterName)
      if (selectedFilters[selectedFilterName] !== null) {
        return filter;
      }

      let attributeValues = filteredVariations?.map((variation) =>
        getVariationAttributeValue(
          variation,
          filter.scope === "LOCAL" ? filter.name.toLowerCase() : filter.name
        )
      );

      filter.enabled_options = attributeValues?.filter(
        (a, i) => !!a && attributeValues?.indexOf(a) === i
      );

      return filter;
    });

    setfilters(filters);
  };

  const addToCart = async () => {
    if (!product) return;

    if (!validateAddToCartProduct(product, filteredVariations, quantity)) {
      return;
    }

    try {
      await cartCtx.addProduct(
        product.databaseId,
        quantity,
        filteredVariations[0]
      );
      setQuantity(1);
    } catch (e) {
      notification.warning(e.message, true);
    }
  };
  //===//

  const productStockQuantity = getStockQuantity(filteredVariations, product);
  const productPrices = getProductPrices(filteredVariations, product);
  const isSimpleProduct = product && product.type === "SIMPLE";

  const gallery = product?.galleryImages?.nodes
    ? product.galleryImages.nodes
    : [product?.image];

  const variationImageSlug = getProductVariationSlug(
    product?.variations?.nodes,
    selectedFilters["pa_ngjyra"] || undefined
  );

  return (
    <div
      className={cn(
        "ProductSingle",
        isSimpleProduct ? "ProductSingle--simple" : "ProductSingle--variable"
      )}
    >
      <Container>
        <HandleLoadingState
          loading={products.loading}
          loadingPlaceholder={
            <Row>
              <Col xs="12" md="7">
                <ProductSingleSliderLoader />
              </Col>
              <Col xs="12" md="5">
                <ProductSingleLoader />
              </Col>
            </Row>
          }
        >
          <ApolloErrorGuard error={products.error}>
            {!product ? (
              <Alert type="warning" message={t(errors.noProductFound)} />
            ) : (
              <div className="ProductSingle">
                <div className="ProductSingle__wrapper">
                  <ProductSingleSlider
                    selectedVariationSlug={variationImageSlug}
                    featureImage={product?.image?.sourceUrl}
                    alt={product?.name}
                    gallery={gallery}
                    isOutOfStock={productStockQuantity === 0}
                  />
                  <div className="ProductSingle__content">
                    <div className="ProductSingle__content__header">
                      <div className="top-content">
                        <div className="ProductSingle__content__barcode">
                          {t("barkodi")}: <b>{product?.sku}</b>
                        </div>
                        {product?.productBrands.nodes[0]?.slug && (
                          <ThemeLink
                            to={`/brands/${product?.productBrands.nodes[0]?.slug}`}
                            className="ProductSingle__content__brand-name"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  product?.productBrands.nodes[0]?.name || ""
                              }}
                            />
                          </ThemeLink>
                        )}
                      </div>
                      {/* <WishListButton id={product.databaseId} />  */}
                      <h1 className="ProductSingle__content__title">
                        {product?.name}
                      </h1>
                      <div
                        className="DefaultPage__content ProductSingle__content__description mt-0"
                        dangerouslySetInnerHTML={{
                          __html: product.description
                        }}
                      />
                    </div>
                    {filters.length > 0 && (
                      <div className="ProductSingle__content__variations_wrapper">
                        {filters?.map(
                          (attribute: ProductSingleAttributeProps) => {
                            return (
                              <div
                                key={attribute.name}
                                className="ProductSingle__content__variations"
                              >
                                {attribute.options ? (
                                  <>
                                    <span className="label">
                                      {t(attribute.name)}
                                      {isSimpleProduct && ":"}
                                    </span>
                                    <div className="d-flex align-items-center">
                                      {attribute.options.map(
                                        (option: string, index: number) => {
                                          let isColor =
                                            attribute.name === "pa_ngjyra";
                                          let isEnabled = attribute.enabled_options?.includes(option) && product?.type === 'VARIABLE'; // prettier-ignore
                                          let disabledClass = !isEnabled && product?.type === 'VARIABLE' ? ' disabled' : ''; // prettier-ignore
                                          const attrName = attribute.scope === 'LOCAL' ? attribute.name.toLowerCase() : attribute.name; // prettier-ignore
                                          let isActiveClass = selectedFilters[attrName] === option ? ' isActive' : ''; // prettier-ignore

                                          return (
                                            <span
                                              key={`${option}-${index}`}
                                              className={`${ isColor ? 'color' : 'size' }${disabledClass}${isActiveClass}`} // prettier-ignore
                                              onClick={() => {
                                                isEnabled &&
                                                  onSelectFilter(
                                                    attrName,
                                                    option
                                                  );
                                              }}
                                            >
                                              {isColor ? (
                                                <div style={isColor ? {backgroundColor: `#${option}`} : {}} /> // prettier-ignore
                                              ) : (
                                                t(option).toUpperCase()
                                              )}
                                            </span>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <p className="mb-0">
                                    {attribute.name} has no options error
                                  </p>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                    <div className="ProductSingle__content__variations">
                      <span className="label mb-0">{t(fields.price)}</span>
                      <ProductPrices
                        regular={productPrices?.regularPrice}
                        sale={productPrices?.salePrice}
                      />
                    </div>

                    {cartCtx.error && (
                      <Alert
                        type="warning"
                        message={cartCtx.error.toString()}
                      />
                    )}

                    <div className="ProductSingle__content__actions mt-auto">
                      <Quantity
                        hideMobile={true}
                        disabled={productStockQuantity === 0}
                        quantity={quantity}
                        maxQuantity={productStockQuantity}
                        onChange={(quantity: number) => setQuantity(quantity)}
                      />

                      <button
                        id="addToCardBtn"
                        onClick={addToCart}
                        disabled={
                          cartCtx.isAddingProduct || productStockQuantity === 0
                        }
                        className={`add_to_cart ${ cartCtx.isAddingProduct || productStockQuantity === 0  ? 'disabled' : ''}`} // prettier-ignore
                      >
                        {cartCtx.isAddingProduct && (
                          <Spinner
                            type="border"
                            color="white"
                            style={{
                              width: "1.25rem",
                              height: "1.25rem",
                              borderWidth: ".2em",
                              marginRight: "1.5em"
                            }}
                          />
                        )}
                        {cartCtx.cartUiState.productAdded
                          ? t(messages.singleProductAdded)
                          : t(actions.addToBag)}
                      </button>

                      {/* <WishListButton id={product.databaseId} /> */}
                      <ProductSingleShareButton />
                    </div>
                  </div>
                </div>
                <ProductSingleTabs product={product} />
              </div>
            )}
            <RelatedProducts
              excludedID={product?.databaseId}
              category={product?.productCategories.nodes[0]?.slug || ""}
            />
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
