import { useQuery } from "react-apollo";
import { GET_TRANSPORT_CONTENT } from "../../queries/generalQueries";

export const useTransportContent = () => {
  const { loading, error, data } = useQuery(GET_TRANSPORT_CONTENT);

  return {
    loading,
    error,
    data: data?.themeOptions?.themeOptions.transport,
  };
};
