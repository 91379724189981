import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

import {
  CheckoutFields,
  useCheckoutFormik
} from "../../../lib/formik/useCheckoutFormik";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import { formInputs, messages } from "../../../lib/translation/strings";
import { setLocalValue } from "../../../lib/helpers/localStorageHelpers";

import { OrderDetails } from "../OrderDetails/OrderDetails";
import { BillingFields } from "../BillingFields/BillingFields";
import { PaymentMethod } from "../PaymentMethod/PaymentMethod";
import { ShippingMethod } from "../ShippingMethod/ShippingMethod";
import { ShippingFields } from "../ShippingFields/ShippingFields";
import { CheckoutHeader } from "../CheckoutHeader/CheckoutHeader";
import { CheckoutFooter } from "../CheckoutFooter/CheckoutFooter";
import { ProfileAddressFields } from "../../ProfilePages/ProfileAddress/ProfileAddress";
import { formatGraphqlErrors } from "../../../lib/helpers/formatGraphqlErrors";
import {
  CHECKOUT_ORDER,
  CheckoutResponse,
  CheckoutVariables,
  CheckoutInputData
} from "../../../queries/mutations/checkoutMutation";

import "./CheckoutForm.scss";
import { ApplyCoupon } from "../ApplyCoupon/ApplyCoupon";
import { LoadingPlaceholder } from "../../shared/LoadingPlaceholder/LoadingPlaceholder";

interface Props {
  customer: ProfileAddressFields;
}

export const CheckoutForm = (props: Props) => {
  const customer = props.customer;
  const authCtx = useAuthContext();
  const router = useRouter();
  const cartCtx = useCartContext();
  const { t } = useTranslation();

  const [error, setError] = useState("");

  const [checkoutMutation, checkoutRes] = useMutation<
    CheckoutResponse,
    CheckoutVariables
  >(CHECKOUT_ORDER);

  // On success, redirect to order confirmation page.
  useEffect(() => {
    const data = checkoutRes?.data?.checkout;
    const isNewUser = formik.values.createAccount;

    if (data?.customer && isNewUser) {
      setLocalValue("authToken", data.customer.jwtAuthToken);
    }
    if (data?.customer && isNewUser) {
      authCtx.updateUser({
        id: data.customer.id,
        email: data.customer.email,
        userId: data.customer.databaseId,
        lastName: data.customer.lastName,
        username: data.customer.username,
        firstName: data.customer.firstName,
        wishlist: []
      });
    }

    if (data?.order?.id) {
      cartCtx.emptyCart(); // TO DO: check this
      router.history.push(`/checkout/${data.order?.id}`);
    }
  }, [checkoutRes]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useCheckoutFormik({
    initialValues: {
      billing: {
        firstName: customer?.billing.firstName || "",
        lastName: customer?.billing.lastName || "",
        address1: customer?.billing.address1 || "",
        city: customer?.billing.city || "",
        phone: customer?.billing.phone || ""
      },
      shipping: {
        firstName: customer?.shipping.firstName || "",
        lastName: customer?.shipping.lastName || "",
        address1: customer?.shipping.address1 || "",
        city: customer?.shipping.city || ""
      },

      email: authCtx.user?.email || "",
      // createAccount: !authCtx.user && false,
      // password: "",
      // passwordConfirm: "",

      billingSameAsShipping: true,
      comment: "",
      termsAndConditions: false,
      paymentMethod: ""
    },
    onSubmit: async (values) => {
      setError("");
      try {
        await checkoutMutation({
          variables: {
            inputData: prepareCheckoutInputData(values)
          }
        });
      } catch (error) {
        window.scrollTo(0, 0);
        //@ts-ignore
        setError(formatGraphqlErrors(error.message));
      }
    }
  });

  const prepareCheckoutInputData = (values: CheckoutFields) => {
    let inputData: CheckoutInputData = {
      clientMutationId: "checkout-react-app",
      billing: {
        ...values.billing,
        email: values.email,
        country: "XK"
      },
      shipToDifferentAddress: !values.billingSameAsShipping,
      paymentMethod: values.paymentMethod,
      shippingMethod: cartCtx.chosenShippingMethod,
      customerNote: values.comment
    };
    // if (values.createAccount && values.password) {
    //   inputData["account"] = {
    //     username: values.email,
    //     password: values.password
    //   };
    // }
    if (!values.billingSameAsShipping) {
      inputData["shipping"] = {
        country: "XK",
        firstName: values.shipping.firstName || "",
        lastName: values.shipping.lastName || "",
        city: values.shipping.city || "",
        address1: values.shipping.address1 || ""
      };
    }
    return inputData;
  };

  return (
    <div className="CheckoutForm">
      {formik.isSubmitting && (
        <LoadingPlaceholder text="Porosia duke u ngarkuar, ju lutemi prisni!" />
      )}
      <CheckoutHeader formik={formik} error={error} />

      <Row>
        <Col xs="12" md="12">
          <BillingFields
            formik={formik}
            isAuthenticated={authCtx.isAuthenticated}
          />
        </Col>
        <Col xs="12" md="12">
          <Row>
            <Col xs="8" md="8">
              {!formik.values.billingSameAsShipping && (
                <div className="Checkout__address-actions d-none d-sm-block">
                  <h4 className="Checkout__address-actions__title">
                    {t(messages.shippingInformation)}
                  </h4>
                </div>
              )}
            </Col>
            <Col xs="4" md="4">
              <div className="Checkout__address-actions float-right">
                <button
                  className={`Checkout__address-actions__delivery d-none d-sm-block${
                    !formik.values.billingSameAsShipping ? " is--active" : ""
                  }`}
                  onClick={() => {
                    formik.setFieldValue(
                      "billingSameAsShipping",
                      !formik.values.billingSameAsShipping
                    );
                    formik.setFieldTouched("billingSameAsShipping");
                  }}
                >
                  {t(messages.toAnotherAddress)}
                </button>
              </div>
            </Col>
          </Row>
          {!formik.values.billingSameAsShipping && (
            <ShippingFields formik={formik} />
          )}
        </Col>
        <Col xs="12" md="6">
          <FormGroup className="form-group">
            <Label for="comment">{t(formInputs.yourComment)}</Label>
            <Input
              id="comment"
              name="comment"
              type="textarea"
              className="form-control"
              value={formik.values.comment}
              onChange={formik.handleChange}
              placeholder={t(formInputs.commentPlaceholder)}
              invalid={!!formik.errors.comment && formik.touched.comment}
            />
            <FormFeedback>{formik.errors.comment}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <ShippingMethod formik={formik} />
          <PaymentMethod formik={formik} />
        </Col>
        <Col xs="12" md="6">
          <OrderDetails />
        </Col>
        <Col xs="12" md="6">
          <ApplyCoupon />
          <CheckoutFooter formik={formik} />
        </Col>
      </Row>
    </div>
  );
};
