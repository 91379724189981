import {
  ProductSingleProps,
  ProductSingleVariationProps
} from "../../../../queries/archive";

export const getStockQuantity = (
  filteredVariations: ProductSingleVariationProps[],
  product?: ProductSingleProps
) => {
  const availableStock = filteredVariations.length === 1 ? filteredVariations[0].stockQuantity : product?.stockQuantity; // prettier-ignore
  return availableStock || 0;
};
