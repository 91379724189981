import React, { useEffect, useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useFetchMenu } from "./lib/useFetchMenu";
import { LoadingPlaceholder } from "../../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

import "./Menu.scss";
import { useQuery } from "react-apollo";
import { GET_BRANDS_LIST } from "../../../queries/brandsQueries";

export const Menu = () => {
  const router = useRouter();
  const { categories, loading } = useFetchMenu();
  const brands = useQuery(GET_BRANDS_LIST, {
    variables: {
      limit: 100
    }
  });
  const [opened, setOpened] = useState(false);
  const [brandsOpened, setBrandsOpened] = useState(false);

  useEffect(() => {
    setOpened(false);
    setBrandsOpened(false);
  }, [router.location.pathname]);

  const handleSetCategories = () => {
    setBrandsOpened(false);
    setOpened(!opened);
  };

  const handleSetBrands = () => {
    setOpened(false);
    setBrandsOpened(!brandsOpened);
  };

  const brandsList = brands?.data?.productBrands?.nodes || [];

  return (
    <div className="Menu">
      <Container>
        <div className="Menu-items">
          <div className="Menu__item Menu__item--sale">
            <NavLink
              to="/sale"
              activeClassName="Menu__item_inner Menu__item--active"
            >
              Në zbritje
            </NavLink>
          </div>
          <div className="Menu__item">
            <NavLink
              to="/shop"
              activeClassName="Menu__item_inner Menu__item--active"
            >
              Produktet
            </NavLink>
          </div>
          <div
            role="button"
            className="Menu__item"
            onClick={handleSetCategories}
          >
            <span
              className={cn("Menu__item_inner", opened && "Menu__item--active")}
            >
              Kategorite
            </span>
          </div>

          <div role="button" className="Menu__item" onClick={handleSetBrands}>
            <span
              className={cn(
                "Menu__item_inner",
                brandsOpened && "Menu__item--active"
              )}
            >
              Brendet
            </span>
          </div>

          <div className="Menu__item">
            <NavLink
              to="/blog"
              activeClassName="Menu__item_inner Menu__item--active"
            >
              Blog
            </NavLink>
          </div>
        </div>
      </Container>

      {opened && (
        <div className="Menu-dropdown">
          <Container>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <div className="Menu-dropdown-wrapper">
                <ul className="Menu-dropdown__list">
                  {categories.map((category) => (
                    <li key={category.id} className="Menu-dropdown__list-item">
                      <NavLink
                        to={`/shop/${category.slug}`}
                        className="Menu-dropdown__list-item-title"
                      >
                        {category?.name}
                      </NavLink>

                      {category?.children?.nodes &&
                        category.children?.nodes.map((c) => (
                          <NavLink
                            key={c.id}
                            to={`/shop/${category.slug}/${c.slug}`}
                          >
                            {c.name}
                          </NavLink>
                        ))}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </div>
      )}

      {brandsOpened && (
        <div className="Menu-dropdown">
          <Container>
            {brands.loading ? (
              <LoadingPlaceholder />
            ) : (
              <div className="Menu-dropdown-wrapper">
                <ul className="Menu-dropdown__list">
                  {brandsList?.map((brand: any) => (
                    <li key={brand.id} className="Menu-dropdown__list-item">
                      <NavLink
                        to={`/brands/${brand.slug}`}
                        className="Menu-dropdown__list-item-title"
                      >
                        {brand?.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};
