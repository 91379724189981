import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import { Brand } from "../../queries/brands";

import { ThemeLink } from "../shared/ThemeLink/ThemeLink";

import imagePlaceholder from "../../assets/images/image-placeholder.png";

import "./BrandItem.scss";

interface Props {
  brand: Brand;
}

export const BrandItem = (props: Props) => {
  return (
    <div className="BrandItem">
      <ThemeLink to={`/brands/${props.brand?.slug}`}>
        <UncontrolledTooltip
          placement="top"
          target={`Brand-${props.brand.slug}`}
        >
          {props.brand.name}
        </UncontrolledTooltip>

        <div className="BrandItem__image" id={`Brand-${props.brand.slug}`}>
          <img
            src={props.brand?.brandData?.logo?.sourceUrl ?? imagePlaceholder}
            alt={props.brand.name}
          />
        </div>
      </ThemeLink>
    </div>
  );
};
