import React, { useRef } from "react";
import { Tooltip } from "reactstrap";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import { Icon } from "../../components/shared/Icon/Icon";

import "./ProductSingleShareButton.scss";

export const ProductSingleShareButton = () => {
  const ref = useRef(null);

  useOutsideClickDetection(ref, () => setOpened(false));

  const [opened, setOpened] = React.useState(false);

  return (
    <>
      <button
        ref={ref}
        id="shareTooltip"
        className="ProductSingleShareButton"
        onClick={() => setOpened(true)}
      >
        <Icon icon="share" />
      </button>
      <Tooltip
        isOpen={opened}
        popperClassName="ProductSingleShareButton__tooltip_wrapper"
        innerClassName="ProductSingleShareButton__tooltip"
        placement="bottom"
        target="shareTooltip"
        trigger="click"
      >
        <ul className="ProductSingleShareButton__items">
          <li>
            <button>
              <Icon icon="facebook" />
            </button>
          </li>
          <li>
            <button>
              <Icon icon="instagram" />
            </button>
          </li>
          <li>
            <button>
              <Icon icon="twitter" />
            </button>
          </li>
        </ul>
      </Tooltip>
    </>
  );
};
