import gql from "graphql-tag";
import { Coupon } from "../../types/generalTypes";

export interface CouponVariables {
  coupon: string;
}

export interface ApplyCouponResponse {
  applyCoupon: {
    cart: {
      total: string;
      appliedCoupons: Coupon[];
    };
  };
}

export interface RemoveCouponResponse {
  removeCoupons: {
    cart: {
      total: string;
      appliedCoupons: {
        nodes: Coupon[];
      };
    };
  };
}

export const applyCoupon = gql`
  mutation applyCoupon($coupon: String!) {
    applyCoupon(input: { clientMutationId: "ADD_COUPON", code: $coupon }) {
      cart {
        total(format: FORMATTED)
        appliedCoupons {
          code
          description
          discountAmount(format: RAW)
          discountTax(format: RAW)
        }
      }
    }
  }
`;

export const removeCoupons = gql`
  mutation removeCoupons($coupon: [String]) {
    removeCoupons(
      input: { clientMutationId: "REMOVE_COUPONS", codes: $coupon }
    ) {
      cart {
        total(format: FORMATTED)
        appliedCoupons {
          code
          description
          discountAmount(format: RAW)
          discountTax(format: RAW)
        }
      }
    }
  }
`;
