import React from "react";
import cs from "classnames";
import { useShopContext } from "../../../lib/context/ShopContext/ShopContext";
import {
  Select as MobileSelect,
  SortSelectOption
} from "../../GeneralFilters/SortSelect/Select";

import "./ShopFilterActions.scss";

interface Props {
  className?: string;
  btnFilterClassName?: string;

  selectInitialValue?: string;
  selectHidden?: boolean;
  selectClassName?: string;
}

const sameOptions = [
  { value: "PRICE-DESC", label: "Cmimit te larte" },
  { value: "PRICE-ASC", label: "Cmimit te ulet" },
  { value: "SLUG-ASC", label: "Shkronjes A" },
  { value: "SLUG-DESC", label: "Shkronjes ZH" }
];

// const desktopSelectOptions: ShopSelectOptions[] = [...sameOptions];

export const ShopFilterActions = (props: Props) => {
  const shopCtx = useShopContext();

  const mobileSelectOptions: SortSelectOption[] = [
    { value: "", label: "Radhit sipas", isPlaceholder: true },
    ...sameOptions
  ];

  // const initialOption = desktopSelectOptions.find(
  //   (o) => o.value === shopCtx?.data?.order
  // );
  // const selectedOptions = initialOption
  //   ? [initialOption]
  //   : ([] as ShopSelectOptions[]);

  const hanleMobileSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (shopCtx.handlers?.handleOrderChange) {
      shopCtx.handlers.handleOrderChange(e.target.value);
    }
  };

  // const handleOnSelect = (selectedOption?: ShopSelectOptions) => {
  //   const value =
  //     selectedOption && selectedOption?.value ? selectedOption.value : "";

  //   if (shopCtx.handlers?.handleOrderChange) {
  //     shopCtx.handlers.handleOrderChange(value);
  //   }
  // };

  return (
    <div className={cs("ShopFilterActions", props.className)}>
      {!props.selectHidden && (
        <>
          <MobileSelect
            value={shopCtx?.data?.order || ""}
            className={cs("ShopFilterActions__btn", props.selectClassName)}
            name="products-order"
            options={mobileSelectOptions}
            onChange={hanleMobileSelectChange}
          />

          {/*<ShopSelect*/}
          {/*  closeOnSelect*/}
          {/*  disableMultiselect*/}
          {/*  showSelectedOption*/}
          {/*  relativeIcon*/}
          {/*  label={t(actions.orderBy)}*/}
          {/*  selected={selectedOptions}*/}
          {/*  options={desktopSelectOptions}*/}
          {/*  onSelect={(selectedOptions) => handleOnSelect(selectedOptions)}*/}
          {/*  className="ShopFilterActions__btn ShopFilterActions__order_desktop"*/}
          {/*/>*/}
        </>
      )}
    </div>
  );
};
