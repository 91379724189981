import React from "react";
import { useTranslation } from "react-i18next";
import { actions, messages } from "../../lib/translation/strings";

import { Verification } from "../Verification/Verification";

import "./Page404.scss";

export const Page404 = () => {
  const {t} = useTranslation();

  return (
    <div className="Page404">
      <Verification
        title="404"
        buttonLink="/"
        desc={t(messages.pageNotFound)}
        buttonTitle={t(actions.backToHome)}
      />
    </div>
  );
};
