import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import { fields, formInputs } from "../../../lib/translation/strings";

import { SelectCity } from "../../shared/SelectCity/SelectCity";
import { HeadingTitle } from "../../shared/HeadingTitle/HeadingTitle";
import { ProfileAddressFormik } from "../../../lib/formik/profile/useProfileAddressFormik";

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressBilling = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <>
      <Col xs="12" sm="12">
        <HeadingTitle title={t(fields.billingAddress)} />
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.name)}</Label>
          <Input
            type="text"
            name="billing.firstName"
            onChange={formik.handleChange}
            value={formik.values.billing.firstName || ""}
            invalid={
              !!formik.errors.billing?.firstName &&
              formik.touched.billing?.firstName
            }
          />
          <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.lastName)}</Label>
          <Input
            type="text"
            name="billing.lastName"
            onChange={formik.handleChange}
            value={formik.values.billing.lastName || ""}
            invalid={
              !!formik.errors.billing?.lastName &&
              formik.touched.billing?.lastName
            }
          />
          <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.address)}</Label>
          <Input
            type="text"
            name="billing.address1"
            onChange={formik.handleChange}
            placeholder={t(formInputs.address)}
            value={formik.values.billing.address1 || ""}
            invalid={
              !!formik.errors.billing?.address1 &&
              formik.touched.billing?.address1
            }
          />
          <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="city">{t(fields.city)}</Label>
          <SelectCity
            name="city"
            hasError={
              !!formik.errors.billing?.city && formik.touched.billing?.city
            }
            errorMessage={formik.errors.billing?.city}
            onSelect={(city: string) => {
              formik.setFieldValue("billing.city", city);
            }}
            initialValue={formik.values.billing.city}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.telephoneNumber)}</Label>
          <Input
            type="text"
            name="billing.phone"
            onChange={formik.handleChange}
            value={formik.values.billing.phone || ""}
            invalid={
              !!formik.errors.billing?.phone && formik.touched.billing?.phone
            }
          />
          <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
        </FormGroup>
      </Col>
    </>
  );
};
