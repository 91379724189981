import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeLink } from "../shared/ThemeLink/ThemeLink";

import { useCartContext } from "../../lib/context/CartContext/CartContext";

import { ProductAttributeProps } from "../../types/productTypes";

import { fields } from "../../lib/translation/strings";
import { getProductPricesN } from "../../lib/helpers/getProductPricesN";
import { removeHTMLEntities } from "../../lib/helpers/removeHTMLEntities";

import { CartNode } from "../../queries/cartQueries";
import { Quantity } from "../shared/QuantityInput/Quantity";
import { ProductPrices } from "../shared/ProductPrices/ProductPrices";
import { WishListButton } from "../shared/WishListButton/WishListButton";
import { RemoveItemButton } from "../RemoveItemButton/RemoveItemButton";

import { Image } from "../shared/Image/Image";
import { Loader } from "../shared/Loader/Loader";

import "./CartItem.scss";

interface Props {
  item: CartNode;
}

const getStockQuantity = (item: CartNode) => {
  const product = item.product;

  if (product.node.type === "SIMPLE") {
    return product.node.stockQuantity || undefined;
  }

  if (product.node.type === "VARIABLE") {
    return (
      item?.variation?.node.stockQuantity ||
      product.node.stockQuantity ||
      undefined
    );
  }
};

export const CartItem = (props: Props) => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();
  const [loading, setLoading] = useState("");

  const { item } = props;
  const stockQuantity = getStockQuantity(item);
  const prices = getProductPricesN(item);

  const handleQuantityUpdate = async (value: number) => {
    try {
      setLoading(item?.product?.node?.id);
      await cartCtx.updateProductQuantity(item.key, value);
    } catch (e) {
    } finally {
      setLoading("");
    }
  };

  return (
    <div className="CartItem">
      {loading === item?.product.node.id && (
        <Loader className="CartItem__loader" />
      )}

      <RemoveItemButton
        disabled={cartCtx.isUpdatingProduct}
        onClick={() => cartCtx.updateProductQuantity(item.key, 0)}
      />

      <div className="CartItem__image">
        {item.product.node.image && (
          <ThemeLink to={`/product/${item.product.node.slug}`}>
            <Image
              dynamicRef={true}
              src={item?.product?.node?.image?.mediaItemUrl}
              srcSet={item?.product?.node?.image.sourceUrl}
              alt={item.product.node.name}
            />
          </ThemeLink>
        )}

        <WishListButton id={item.product.node.databaseId} />

        {item.product.node.productBrands.nodes[0] && (
          <span className="CartItem__image__brand">
            {removeHTMLEntities(item.product.node.productBrands.nodes[0].name)}
          </span>
        )}
      </div>
      <div className="CartItem__content">
        <ThemeLink to={`/product/${item.product.node.slug}`}>
          <h3 className="CartItem__content__title">{item.product.node.name}</h3>
        </ThemeLink>

        <ProductPrices sale={prices.sale} regular={prices.regular} />

        <div className="CartItem__attributes">
          <ul className="CartItem__attributes__list">
            <CartItemAttributes
              name={item.product.node.name}
              attributes={item.variation?.node?.attributes}
            />
          </ul>
          <div className="CartItem__attributes__quantity">
            <span className="CartItem__attributes__title">
              {t(fields.quantity)}
            </span>
            <Quantity
              disabled={cartCtx.isUpdatingProduct}
              loading={loading === item?.product?.node?.id}
              disableDecrement={item?.quantity === 1}
              disableIncrement={item?.quantity === stockQuantity}
              maxQuantity={stockQuantity}
              quantity={item.quantity}
              onChange={(value: number) => handleQuantityUpdate(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface CartItemAttributesProps {
  name: string;
  attributes: ProductAttributeProps | undefined;
}

export const CartItemAttributes = (props: CartItemAttributesProps) => {
  const filteredAttributes = props.attributes?.nodes.filter(
    (item) => item.name === "pa_ngjyra" || item.name === "pa_madhesia"
  );

  return (
    <div>
      {filteredAttributes?.map((item) => {
        const isColor = item.name === "pa_ngjyra";

        if (!item.value) {
          return;
        }

        return (
          <li key={`${props.name}-variation-${item.name}`}>
            <span
              className="CartItem__attributes__value"
              style={isColor ? { backgroundColor: `#${item.value}` } : {}}
            >
              {isColor ? "" : item.value}
            </span>
          </li>
        );
      })}
    </div>
  );
};
