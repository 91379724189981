import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import { ProductSingleSliderItem } from "./ProductSingleSliderItem/ProductSingleSliderItem";
import { Image } from "../shared/Image/Image";

import "./ProductSingleSlider.scss";

interface Props {
  selectedVariationSlug?: string;
  featureImage: string;
  alt: string;
  gallery?: any;
  isOutOfStock?: boolean;
}

export const ProductSingleSlider = (props: Props) => {
  const { t } = useTranslation();
  const [slider1, setSlider1] = useState<any>();
  const [slider2, setSlider2] = useState<any>();

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    asNavFor: slider2
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: slider1,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true
  };

  useEffect(() => {
    if (props.selectedVariationSlug === undefined) {
      return;
    }

    const img = props.gallery?.find(
      (item: any) => item.slug === props.selectedVariationSlug
    );

    if (!img) {
      return;
    }

    const imgIndex = props.gallery?.indexOf(img);

    if (imgIndex === undefined) {
      return;
    }

    slider1?.slickGoTo(imgIndex);
    slider2?.slickGoTo(imgIndex);
  }, [props.selectedVariationSlug, props.gallery, slider1, slider2]);

  const sliderImages = props.gallery || [];

  return (
    <div className="ProductSingleSlider">
      {props.isOutOfStock && (
        <div className="ProductSingleSlider--nostock">
          <span>{t("e shitur")}</span>
        </div>
      )}

      {(sliderImages.length > 0) ? (
        <Slider
          {...settingsMain}
          ref={(slider) => setSlider1(slider)}
          className="ProductSingleSlider__gallery"
        >
          {sliderImages.map((image: { sourceUrl: string }, index: number) => {
            return (
              <ProductSingleSliderItem
                image={image.sourceUrl}
                key={`${image.sourceUrl}-${index}`}
              />
            );
          })}
        </Slider>
      ) : (
        <Image />
      )}

      {/*Thumb slider*/}
      {sliderImages.length > 0 && (
        <Slider
          {...settingsThumbs}
          className="ProductSingleSlider__thumbs"
          ref={(slider) => setSlider2(slider)}
        >
          {sliderImages.map((image: { sourceUrl: string }, index: number) => {
            return (
              <div
                key={`${image.sourceUrl}-${index}`}
                className="ProductSingleSlider__thumbs__item"
              >
                <Image src={image.sourceUrl} alt={props.alt} />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};
