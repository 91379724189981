import React from "react";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import { Container, Input } from "reactstrap";
import { useFormik } from "formik";
import { Button } from "../../shared/Button/Button";
import { SUBSCRIBE } from "../../../queries/mutations/generalMutation";
import "./Newsletter.scss";

export const Newsletter = () => {
  const [mailchimpSubscription] = useMutation(SUBSCRIBE);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await mailchimpSubscription({
          variables: {
            clientMutationId: "mailchimp-subscription",
            email: values.email
          }
        });
        formikHelpers.resetForm();
      } catch (error) {
        formik.setFieldError("email", error.message);
      }
    }
  });

  return (
    <div className="Newsletter">
      <Container>
        <div className="Newsletter__wrapper">
          <h2 className="Newsletter__text">Abonohu ne te rejat tona!</h2>
          <div className="Newsletter__form">
            {/*subscription?.data.mailchimpSubscription.message ? (*/}
            {/*<p>{subscription?.data.mailchimpSubscription.message}</p>*/}
            {/*) : (*/}
            {/*<>        {subscription?.data &&*/}

            <Input
              placeholder="Email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              invalid={!!formik.errors.email && formik.touched.email}
            />
            <Button
              loading={formik.isSubmitting}
              color="purple"
              rightIcon="arrow-right"
              onClick={formik.handleSubmit}
            >
              Dërgo
            </Button>
            {/*  </>*/}
            {/*)}*/}
          </div>
        </div>
      </Container>
    </div>
  );
};
