import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import { errors } from "../../lib/translation/strings";

import { BrandItem } from "./BrandItem";
import { BulkLoader } from "../shared/BulkLoader/BulkLoader";
import { InfoMessage } from "../shared/InfoMessage/InfoMessage";
import { LoadMoreButton } from "../shared/LoadMoreButton/LoadMoreButton";
import { BrandListItemPlaceholder } from "./ui/BrandListItemPlaceholder";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";

import { Brand } from "../../queries/brands";

import "./BrandsList.scss";

interface Props {
  hasNextPage?: boolean;
  isLoadingMore?: boolean;
  loading?: boolean;
  brands: Brand[];

  onLoadMore: () => void;
}

export const BrandsList = (props: Props) => {
  const { t } = useTranslation();
  const noAvailableBrands = !props.brands || Number(props.brands.length) === 0;

  return (
    <>
      <div
        className={cs(
          "BrandsList",
          noAvailableBrands && !props.loading && "BrandsList--empty"
        )}
      >
        <HandleLoadingState
          loading={props.loading}
          loadingPlaceholder={
            <BulkLoader length={15} component={BrandListItemPlaceholder} />
          }
        >
          {noAvailableBrands ? (
            <InfoMessage
              buttonLink="/shop"
              title={t(errors.noBrands)}
              description={t(errors.noBrandsDescription)}
            />
          ) : (
            props.brands.map((brand) => {
              return <BrandItem brand={brand} key={brand.id} />;
            })
          )}
        </HandleLoadingState>
      </div>
      {props.hasNextPage && (
        <LoadMoreButton
          onClick={props.onLoadMore}
          loading={props.isLoadingMore || false}
        />
      )}
    </>
  );
};
