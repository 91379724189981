import React from "react";
// import {
//   MagnifierContainer,
//   MagnifierPreview,
//   MagnifierZoom
// } from "react-image-magnifiers";

import "./ProductSingleSliderItem.scss";

interface Props {
  alt?: string;
  image: string;
}

export const ProductSingleSliderItem = (props: Props) => {
  return (
    <div className="ProductSingleSlider__item">
      <img src={props.image} alt={props.alt} />
      {/* <MagnifierContainer>
        <MagnifierPreview imageSrc={props.image} />
        <MagnifierZoom style={{ height: "400px" }} imageSrc={props.image} />
      </MagnifierContainer> */}
    </div>
  );
};
