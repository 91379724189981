import React from "react";
import { useTranslation } from "react-i18next";

import { actions } from "../../lib/translation/strings";

import { CheckoutFormik } from "../../lib/formik/useCheckoutFormik";
import { DefaultButton } from "../../components/shared/DefaultButton/DefaultButton";

import "./Cart.scss";

interface Props {
  formik?: CheckoutFormik;
}

export const CartTotal = (props: Props) => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    if (props.formik?.isSubmitting || !props.formik) {
      return;
    }

    props.formik.submitForm();
  };

  const buttonTitle = props.formik?.isSubmitting
    ? t(actions.processing)
    : t(actions.continueWithPurchase);

  return (
    <div className="CartTotal">
      {props.formik ? (
        <DefaultButton
          title={buttonTitle}
          onClick={handleOnClick}
        />
      ) : (
        <DefaultButton to="/checkout" title={t(actions.continueWithPurchase)} />
      )}
    </div>
  );
};
