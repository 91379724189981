import React, { useState } from "react";
import cn from "classnames";
import { NewIn } from "./tabs/NewIn";
import { OnSale } from "./tabs/OnSale";

import "./HomeTabs.scss";

const tabs = [
  {
    id: "new-in",
    label: "Të rejat",
    component: NewIn
  },
  {
    id: "on-sale",
    label: "Në zbritje",
    component: OnSale
  }
];

export const HomeTabs = () => {
  const [active, setActive] = useState<string>("new-in");

  const Component = tabs.find(f => f.id === active)?.component;

  return (
    <div className="HomeTabs" id="home-tabs">
      <ul className="HomeTabs__tabs">
        {tabs.map(tab => (
          <li
            key={tab.id}
            onClick={() => setActive(tab.id)}
            className={cn(
              "HomeTabs__tab",
              active === tab.id && "HomeTabs__tab--active"
            )}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      {Component && <Component />}
    </div>
  );
};
