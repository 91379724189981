import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createApolloClient } from "./apollo/createApolloClient";
import { CartContextProvider } from "./lib/context/CartContext/CartContextProvider";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
// import { LanguageContextProvider } from "./lib/context/LanguageContext/LanguageContextProvider";
import { App } from "./App";

import "./styles/main.scss";
import { MenuSidebarContextProvider } from "./lib/context/MenuSidebarContext/MenuSidebarContextProvider";

const apolloClient = createApolloClient();

const element = (
  <React.Fragment>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        {/*<LanguageContextProvider>*/}
        <AuthContextProvider>
          <CartContextProvider>
            <MenuSidebarContextProvider>
              <App />
            </MenuSidebarContextProvider>
          </CartContextProvider>
        </AuthContextProvider>
        {/*</LanguageContextProvider>*/}
      </BrowserRouter>
    </ApolloProvider>
  </React.Fragment>
);

ReactDOM.render(element, document.getElementById("root"));

serviceWorker.unregister();
