import React, { useState } from "react";
import cn from "classnames";

import { ProductOptionTab, ProductSingleProps } from "../../../queries/archive";
import { useTransportContent } from "../../../lib/hooks/useTransportContent";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { ProductSingleLoader } from "../../../pages/ProductSingle/ProductSingleLoader";

import "./ProductSingleTabs.scss";

interface Props {
  product: ProductSingleProps;
}

const HowToUse = (props: Props) => {
  return (
    <div>
      <div className="row">
        {props.product.productOptions?.howToUse?.map(
          (item: ProductOptionTab, index: number) => {
            return (
              <div key={`${item.title}-${index}`} className="col-12 col-sm-4">
                <div className="DefaultPage__content mt-0">
                  <h3>{item.title}</h3>
                  <div
                    className="DefaultPage__content mt-0"
                    dangerouslySetInnerHTML={{
                      __html: item.description
                    }}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
const Transport = (props: Props) => {
  const transport = useTransportContent();
  return (
    <>
      <HandleLoadingState
        loading={transport.loading}
        loadingPlaceholder={<ProductSingleLoader />}
      >
        <ApolloErrorGuard error={transport.error}>
          <div
            className="DefaultPage__content mt-0"
            dangerouslySetInnerHTML={{
              __html: transport.data
            }}
          />
        </ApolloErrorGuard>
      </HandleLoadingState>
    </>
  );
};
const Ingridients = (props: Props) => {
  return (
    <div>
      {props.product.productOptions?.ingridients && (
        <div className="DefaultPage__content mt-0">
          <h3>{props.product.productOptions?.ingridients.title}</h3>
          <div
            className="DefaultPage__content mt-0"
            dangerouslySetInnerHTML={{
              __html: props.product.productOptions?.ingridients.description
            }}
          />
        </div>
      )}
    </div>
  );
};
const Benefits = (props: Props) => {
  return (
    <div>
      {props.product.productOptions?.benefits && (
        <div className="DefaultPage__content mt-0">
          <h3>{props.product.productOptions?.benefits.title}</h3>
          <div
            className="DefaultPage__content mt-0"
            dangerouslySetInnerHTML={{
              __html: props.product.productOptions?.benefits.description
            }}
          />
        </div>
      )}
    </div>
  );
};
const SkinType = (props: Props) => {
  return (
    <div>
      {props.product.productOptions?.skintype && (
        <div className="DefaultPage__content mt-0">
          <h3>{props.product.productOptions?.skintype.title}</h3>
          <div
            className="DefaultPage__content mt-0"
            dangerouslySetInnerHTML={{
              __html: props.product.productOptions?.skintype.description
            }}
          />
        </div>
      )}
    </div>
  );
};
const QuestionAnswers = (props: Props) => {
  return (
    <div>
      {props.product.productOptions?.qa && (
        <div className="DefaultPage__content mt-0">
          <h3>{props.product.productOptions?.qa.title}</h3>
          <div
            className="DefaultPage__content mt-0"
            dangerouslySetInnerHTML={{
              __html: props.product.productOptions?.qa.description
            }}
          />
        </div>
      )}
    </div>
  );
};

const tabs = [
  {
    id: "howToUse",
    label: "Si ta perdorni?",
    component: HowToUse
  },
  {
    id: "benefits",
    label: "Përfitimet",
    component: Benefits
  },
  {
    id: "skintype",
    label: "Lloji i lëkures",
    component: SkinType
  },
  {
    id: "transport",
    label: "Transporti",
    component: Transport
  },
  {
    id: "ingridients",
    label: "Përbersit",
    component: Ingridients
  },
  {
    id: "qa",
    label: "Q&A",
    component: QuestionAnswers
  },
];

export const ProductSingleTabs = (props: Props) => {
  const [active, setActive] = useState<string | undefined>(undefined);

  const Component = tabs.find((f) => f.id === active)?.component;
  const productOptions = props.product.productOptions as any;

  const tabsWithContent = tabs.filter((tab) => {
    if (tab.id === "transport") {
      return tab;
    }
    if (tab.id === "howToUse") {
      return !!productOptions?.howToUse && productOptions?.howToUse.length > 0;
    }

    const foundKeys = Object.keys(productOptions)?.filter(
      (key) => tab.id === key
    );

    return foundKeys.length > 0 && !!productOptions[foundKeys[0]]?.description;
  });

  !active && tabsWithContent.length > 0 && setActive(tabsWithContent[0].id);

  return (
    <div className="ProductSingleTabs">
      <ul className="ProductSingleTabs__tabs">
        {tabsWithContent.map((tab) => (
          <li
            key={tab.id}
            onClick={() => setActive(tab.id)}
            className={cn(
              "ProductSingleTabs__tab",
              active === tab.id && "ProductSingleTabs__tab--active"
            )}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      {Component && <Component {...props} />}
    </div>
  );
};
