import gql from "graphql-tag";
import {
  AttributeFilter,
  ChosenAttributesInput,
  PageInfo,
  ProductTaxonomyFilterInput
} from "../types/generalTypes";
import { Product } from "../types/productTypes";

export interface GetProductsVariables {
  limit: number;
  before?: string;
  after?: string;
  category?: string;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
  onSale?: boolean;
}

export interface GetProductsResponse {
  products: {
    nodes: Product[];
    pageInfo?: PageInfo;
  };
}

export const getProducts = gql`
  query getProducts(
    $last: Int
    $limit: Int
    $after: String
    $before: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    # $taxonomyFilter: [ProductTaxonomyFilterInput]
    $onSale: Boolean
  ) {
    products(
      last: $last
      first: $limit
      after: $after
      before: $before
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        # taxonomyFilter: { or: $taxonomyFilter }
        onSale: $onSale
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        productBrands {
          nodes {
            name
            slug
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export interface ShopProductCategory {
  id: string;
  link: string;
  slug: string;
  name: string;
  children: {
    nodes: ShopProductCategory[];
  };
}

export interface GetShopProductCategoriesVariables {
  customTaxonomy?: ChosenAttributesInput[];
}

export interface GetShopProductCategoriesResponse {
  productCategories: {
    nodes: ShopProductCategory[];
  };
}

export const getProductCategories = gql`
  query getProductCategories($customTaxonomy: [ChosenAttributesInput]) {
    productCategories(
      first: 100
      where: { hideEmpty: true, exclude: "15", customTaxonomy: $customTaxonomy }
    ) {
      nodes {
        id
        link
        name
        slug
        children(where: { hideEmpty: true, customTaxonomy: $customTaxonomy }) {
          nodes {
            id
            name
            link
            slug
          }
        }
      }
    }
  }
`;

export interface GetAttributeFiltersVariables {
  chosenAttributes: ChosenAttributesInput[];
}

export interface GetAttributeFiltersResponse {
  attributeFilters: AttributeFilter[];
}

export const getAttributeFilters = gql`
  query getAttributeFilters($chosenAttributes: [ChosenAttributesInput]) {
    attributeFilters(chosenAttributes: $chosenAttributes) {
      id
      name
      label
      slug
      terms {
        id
        count
        name
        slug
      }
    }
  }
`;
