import React from "react";
import ContentLoader from "react-content-loader";

import "./BrandListItemPlaceholder.scss";

export const BrandListItemPlaceholder = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={110}
    viewBox="0 0 300 110"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="BrandListItemPlaceholder"
  >
    <rect x="0" y="0" rx="0" ry="0" width="300" height="110" />
  </ContentLoader>
);
