import React, { useState, useEffect } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import { actions } from "../../../lib/translation/strings";

import "./SearchInput.scss";
import { Icon } from "../../shared/Icon/Icon";

interface Props {
  name: string;
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const SearchInput = (props: Props) => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState(props.value || "");

  useEffect(() => {
    if (props.value) {
      setKeyword(props.value);
    }
  }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener("keyup", handleOnEscPress);
    return () => {
      document.removeEventListener("keyup", handleOnEscPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnEscPress = (e: KeyboardEvent) => {
    if (e.key && e.key === "Escape") {
      handleClear();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    props.onChange(e.target.value);
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const handleClear = () => {
    setKeyword("");
    props.onChange("");
  };

  return (
    <span className={cs("SearchInput", props.className)}>
      <input
        type="text"
        value={keyword}
        name={props.name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        className={cs("SearchInput__form_control")}
        placeholder={props.placeholder || t(actions.search)}
      />
      {props.value.length > 0 && (
        <span className="SearchInput__clear" onClick={handleClear}>
          <Icon icon="x" />
        </span>
      )}
    </span>
  );
};
