import { ProductSingleProps } from "../../../../queries/archive";

export const getFirstVariationWithQuantity = (product?: ProductSingleProps) => {
  if (product?.type === "SIMPLE") {
    return null;
  }

  return product?.variations?.nodes?.find(
    variation => variation.stockQuantity > 0
  );
};
